'use script';

import './assets/styling/main.scss';

let mailHandler = document.querySelector('.email.enc');
const dec = (string) => {
  return window.atob(string);
};

mailHandler.addEventListener('click', (e) => {
  const fullAddr = `${dec(mailHandler.dataset.handle)}@gmail.com`;

  if (mailHandler.classList.contains('enc')) {
    e.preventDefault();
    mailHandler.classList.remove('enc');
    mailHandler.textContent = fullAddr;
    mailHandler.href = `mailto:${fullAddr}`;
  }
});
